import React, { useContext, useEffect } from "react";
import { findLocationByVal } from "common/Constants";
import Button from "components/Button";
import AppContext from "context";
import { NGN } from "pages/flight/utils";
import { ReactComponent as PayStackSvg } from "assets/svgs/Paystack.svg";
import { ReactComponent as InfoRedSvg } from "assets/svgs/InfoRed.svg";
import { useMutation } from "react-query";
import { generatePaymentTransaction, verifyPayment } from "pages/payment/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { RouteEnum } from "routes";
import {
  enableProceedToPayment,
  logger,
  passengerIsNotValid,
  validateEmail,
} from "common/utils";
import { isEmpty } from "lodash";

const BookingSummary = ({ isMobileView = false }) => {
  const {
    selectedBooking = {},
    passengers = [],
    sessionId,
    selectedAirlineConfig,
    hasReadTermsAndCondition,
    confirmation,
    auth,
  } = useContext(AppContext);

  const history = useHistory();

  const {
    dep_time,
    arrival_time,
    flightInfo: { arrival_city, dep_city },
    originalCost,
  } = selectedBooking;

  const { costBreakdown, totalCost } = getBookingCostingDetails(
    passengers,
    originalCost,
    selectedAirlineConfig
  );

  const enablePayNow = enableProceedToPayment(
    confirmation,
    hasReadTermsAndCondition
  );

  const loggedInUserId = auth?._id;

  const {
    mutate: onLoadTransactionReference,
    isLoading: isLoadingTransactionReference,
    error: generateTransactionRefError,
  } = useMutation(generatePaymentTransaction, {
    onSuccess(res) {
      payWithPaystack(res);
    },
  });

  const {
    mutate: onVerifyPayment,
    isLoading: isLoadingPaymentVerification,
    error: paymentVerificationError,
  } = useMutation(verifyPayment, {
    onSuccess(res) {
      logger("Paystack Response", res);
      // send the use to print their booking
      history.push(`${RouteEnum.Print}?reference=${res?.data?.reference}`);
    },

    onError(err) {
      toast(
        "Unable to complete payment, please try again or contact our support."
      );
      logger("Error with paystack");
    },
  });

  const createPassengerBooking = (paystackRespone) => {
    console.log("Paystack Response ", paystackRespone);
    const { reference, status, message, transaction } = paystackRespone;
    const bookingPayload = {
      reference,
      status,
      message,
      session_id: sessionId,
      transaction,
    };
    onVerifyPayment(bookingPayload);
  };

  const payWithPaystack = (paymentInfo) => {
    const formElem = document.getElementById("paystackModal");
    // TODO check if the script has been loaded before
    let payStackInline = document.createElement("script");
    payStackInline.setAttribute("id", "rrPaystack");
    payStackInline.onload = () => {
      lunchPaystackPopup(paymentInfo, createPassengerBooking);
    };
    payStackInline.setAttribute("src", "https://js.paystack.co/v1/inline.js");
    formElem.appendChild(payStackInline);
  };

  const handlePayNow = () => {
    const { email, phone } = confirmation;
    let isValid = true;
    if (!email || !validateEmail(email)) {
      toast.error("Please provide a valid confirmation email");
      isValid = false;
      return;
    }

    if (!phone) {
      toast.error("Provide an active phone number");
      isValid = false;
      return;
    }

    const passengerNotValidMessage = passengerIsNotValid(passengers);
    if (passengerNotValidMessage) {
      toast.error(passengerNotValidMessage);
      isValid = false;
      return;
    }
    if (isValid) {
      onLoadTransactionReference(sessionId);
    }
  };

  useEffect(() => {
    if (generateTransactionRefError) {
      toast.error("Error generating transaction reference");
    }
  }, [generateTransactionRefError]);

  const disablePayNowBtn =
    isLoadingTransactionReference ||
    isLoadingPaymentVerification ||
    !enablePayNow;

  return (
    <div className="rounded-3xl flex flex-col w-full bg-white px-6 py-7">
      <div className="font-black text-center text-[20px] leading-[28px]">
        Booking Summary
      </div>

      <div className="border border-gray-400 my-4" />

      {/* Travel details */}
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col space-y-2">
          <div className="font-bold text-[14px] leading-[22px] text-gray-400">
            {dep_time}
          </div>
          <div className="font-black text-[20px] leading-[28px]">
            {dep_city}
          </div>
          <div className="text-[12px] leading-5 font-bold text-gray-400">
            {findLocationByVal(dep_city)?.label}
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <div className="font-bold text-[14px] leading-[22px] text-gray-400">
            {arrival_time}
          </div>
          <div className="font-black text-[20px] leading-[28px]">
            {arrival_city}
          </div>
          <div className="text-[12px] leading-5 font-bold text-gray-400">
            {findLocationByVal(arrival_city)?.label}
          </div>
        </div>
      </div>

      {/* Breakdown */}
      <div className="flex flex-col space-y-[9px] mt-[35px]">
        {Object.keys(costBreakdown).map((k) => {
          const { count, cost } = costBreakdown[k];
          return <Info key={`key-${k}`} cost={cost} count={count} label={k} />;
        })}
      </div>

      {/* Total */}

      <div className="flex flex-row justify-between mt-[22px]">
        <span className="font-normal text-gray-700 text-[18px] leading-[26px]">
          Total
        </span>
        <span className="font-bold text-gray-900 text[18px] leading-[26px]">
          {NGN(totalCost).format()}
        </span>
      </div>

      <div className="flex flex-row items-center bg-red-200 py-[9px] px-[15.5px] mt-[22px] rounded-lg">
        <span>
          <InfoRedSvg />
        </span>
        <span className="font-bold text-[10px] text-red-600 ml-[6px]">
          This price may increase if you book later.
        </span>
      </div>

      {!disablePayNowBtn && (
        <div className="flex flex-row justify-between mt-[22px]">
          <Button
            disabled={disablePayNowBtn}
            onClick={handlePayNow}
            className="bg-site-primary rounded h-[48px] w-full  text-white font-bold"
          >
            Pay Now
          </Button>
        </div>
      )}

      <div className="flex flex-col mt-8 items-center">
        <span className="text-gray-700 font-bold text-[15px] leading-[26px]">
          Secure Payment by
        </span>

        <div>
          <PayStackSvg />
        </div>
      </div>
    </div>
  );
};

const Info = ({ label = "Adult", count = 1, cost = 0 }) => {
  const total = NGN(parseFloat(cost)).format();
  return (
    <div className="flex flex-row justify-between">
      <span className="font-bold text-gray-500 text-[14px] leading-[22px]">
        {`${label} x ${count}`}
      </span>
      <span className="font-bold text-gray-900 text-xs leading-[22px]">
        {total}
      </span>
    </div>
  );
};

const getBookingCostingDetails = (
  passengers = [],
  originalCost = 0,
  selectedAirlineConfig
) => {
  let Adult = 0;
  let Child = 0;
  let Infant = 0;
  const { configs } = selectedAirlineConfig;
  const _originalCost = originalCost;
  passengers.forEach((p) => {
    const passengerType = p.passenger_type || "adult_fare";
    if (passengerType === "adult_fare") {
      Adult += 1;
    }
    if (passengerType === "child_fare") {
      Child += 1;
    }
    if (passengerType === "infant_fare") {
      Infant += 1;
    }
  });
  const infantPercentage =
    originalCost * (parseFloat(configs?.infant_fare || 1) / 100);

  const totalChildCost =
    (_originalCost - parseFloat(configs?.child_fare || 0)) * Child;
  const totalAdultCost = _originalCost * Adult;
  const totalInfantCost = infantPercentage * Infant;

  const subTotalCost = totalChildCost + totalAdultCost + totalInfantCost;
  const additonalCharges =
    subTotalCost * (parseFloat(configs?.additional_charges || 1) / 100);
  const totalCost = subTotalCost + additonalCharges;

  return {
    costBreakdown: {
      Adult: { count: Adult, cost: totalAdultCost },
      Child: { count: Child, cost: totalChildCost },
      Infant: { count: Infant, cost: totalInfantCost },
    },
    totalCost,
  };
};

function lunchPaystackPopup(paymentInfo, fnPaystackSuccess) {
  const pubKey = paymentInfo.pub_key;
  const chargeableAmount = paymentInfo.amount;
  const tranRef = paymentInfo.transaction_ref;
  console.log(":: Payment info ", paymentInfo);
  const email = paymentInfo.email;
  if (!email) {
    toast("Please provide your confirmation");
    return;
  }
  let handler = window.PaystackPop.setup({
    key: pubKey,
    email: email,
    amount: chargeableAmount * 100,
    ref: tranRef,
    callback: (response) => {
      fnPaystackSuccess(response);
    },
    onClose: () => {
      console.log("You cancel your payment. \n Click pay again to continue");
    },
  });
  handler.openIframe();
}
export { Info, lunchPaystackPopup, getBookingCostingDetails };
export default BookingSummary;
