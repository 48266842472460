import React, { useContext } from "react";
import { ReactComponent as HambugerSvg } from "assets/svgs/Hambuger.svg";
import { ReactComponent as Logo } from "assets/svgs/fc_logo.svg";
import { Link } from "react-router-dom";
import AppContext from "context";
import { RouteEnum } from "routes";

const Header = () => {
  const { authToken, auth } = useContext(AppContext);
  const { user_type: userType } = auth;
  const isAdminUser = userType === "ADMIN";

  return (
    <header className="flex w-full px-8 mt-8 md:px-[144px]">
      <div className="flex">
        <Link to="/">
          <Logo />
        </Link>
      </div>

      <div className="flex ml-auto ">
        <nav className="hidden md:flex f md:flex-row md:space-x-8 md:justify-center md:items-center font-bold text-lg leading-6 text-site-secondary ">
          {isAdminUser && authToken && (
            <Link
              to="/admin"
              className=" hover:underline hover:text-site-primary"
            >
              Admin
            </Link>
          )}

          <Link to="/" className=" hover:underline hover:text-site-primary">
            Home
          </Link>

          {isAdminUser && authToken && (
            <Link
              to={RouteEnum.CustomerBooking}
              className="hover:underline hover:text-site-primary"
            >
              Manage Booking
            </Link>
          )}

          <a
            href="https://flightcompare.substack.com/"
            target="_blank"
            rel="noreferrer"
            className="hover:underline hover:text-site-primary"
          >
            Blog
          </a>

          {authToken ? (
            <Link
              to="/logout"
              className="flex flex-row items-center justify-center bg-site-secondary rounded py-2  px-3  text-white font-bold hover:text-site-primary "
            >
              Logout
            </Link>
          ) : (
            <>
              <Link
                to="/login"
                className="hover:underline hover:text-site-primary"
              >
                Login
              </Link>

              <Link
                to="/signup"
                className="flex flex-row items-center justify-center bg-site-secondary rounded py-2  px-3  text-white font-normal hover:text-site-primary "
              >
                Create free account
              </Link>
            </>
          )}
        </nav>

        <div className="mr-2 hidden">
          <HambugerSvg />
        </div>
      </div>
    </header>
  );
};

export default Header;
