import Http from "common/Http";

const ENDPOINTS = {
  BOOKING: "/booking",
  SESSION: "/booking/session",
};

export const createBookingSession = async (data) => {
  const res = await Http.post(ENDPOINTS.SESSION, data);
  return res?.data;
};

export const updateBookingSession = async (data) => {
  const res = await Http.put(ENDPOINTS.SESSION, data);
  return res?.data;
};

export const getBookingSession = async (sessionId) => {
  const res = await Http.get(`${ENDPOINTS.SESSION}?session_id=${sessionId}`);
  return res?.data;
};


export const getBookingByReference = async (reference) => {
  const res = await Http.get(`${ENDPOINTS.BOOKING}/ref?reference=${reference}`);
  return res?.data;
};
