import Container from "components/Container";
import Loader from "components/Loader";
import RouteComposer from "components/RouteComposer";
import AppContext from "context";
import React, { useContext, useEffect } from "react";
import { Link, Switch, useHistory } from "react-router-dom";
import { AdminRoutes, RouteEnum } from "routes";

const AdminContainer = () => {
  // TODO check if the user is authenticated

  const { auth, isFetchingAuth } = useContext(AppContext);
  const { user_type: userType } = auth;
  const history = useHistory()
  const menus = [
    {
      to: RouteEnum.Admin,
      name: "Dashboard",
    },
    {
      to: RouteEnum.AdminBooking,
      name: "Bookings",
    },

    {
      to: RouteEnum.AdminUsers,
      name: "Users",
    },

    {
      to: RouteEnum.AdminConfig,
      name: "Settings",
    },
  ];

  useEffect(() => {
    if (!isFetchingAuth && userType) {
      if ( userType !== "ADMIN")
        history.push(RouteEnum.Login)
    }
  }, [userType, isFetchingAuth])


  if (isFetchingAuth) {
    return <Loader/>
  }
  return (
    <Container>
      <div className="flex flex-row w-full">
        <div className="w-1/5 mr-8">
          <div className="flex flex-col space-y-3">
            {menus.map((menu) => {
              return (
                <div key={menu.name} className="flex flex-row justify-center items-center border border-site-secondary rounded-lg bg-site-secondary text-white h-12 text-lg uppercase">
                  <Link to={menu.to} className="hover:text-site-primary hover:cursor-pointer">
                    {menu.name}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-4/5">
          <RouteComposer
            routes={AdminRoutes}
            wrapper={(children) => {
              return <Switch>{children}</Switch>;
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default AdminContainer;
