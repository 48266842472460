import React from "react";
import ROUTES from "routes";
import RouteComposer from "components/RouteComposer";
import { Switch } from "react-router-dom";

const PublicContainer = ({ }) => {
  return (
    <RouteComposer
      routes={ROUTES}
      wrapper={(children) => {
        return <Switch>{children}</Switch>;
      }}
    />
  );
};

export default PublicContainer
