import Cookies from "js-cookie";

export const USER_TOKEN = "user-token";
const getHostDomain = () => {
  const hostname = window.location.hostname;
  if (hostname.includes("localhost")) {
    return hostname;
  }
  // eslint-disable-next-line
  const [_, domain, tld] = hostname.split(".");
  if (!tld) {
    return hostname
  }
  return `.${domain}.${tld}`;
};
export function setToken(token) {
  Cookies.set(USER_TOKEN, token, { path: '', domain: getHostDomain() });
}

export function getToken() {
  return Cookies.get(USER_TOKEN);
}

export function removeToken() {
  return Cookies.remove(USER_TOKEN, { path: '', domain: getHostDomain() });
}
