import React from 'react';

const Container = ({ children }) => {
  return (
    <div className='w-full flex flex-col md:flex-row md:justify-between space-x-0  md:space-x-5 px-8 mt-8 md:px-[144px]'>
      {children}
    </div>
  );
};

export default Container;
