import React from "react";
import FlightLoadingGif from "assets/airlines/flight-loading.gif";

const Loader = () => {
  return (
    <div className="z-[100000]">
      <div className="flex justify-center items-center">
        <img width={90} height={90} src={FlightLoadingGif} alt="Loading..." />
      </div>
    </div>
  );
};

export default Loader;
