import { getToken, removeToken } from "./Cookies";
import Http from "./Http";

export default function initailizerHttp() {
  Http.interceptors.request.use((config) => {
    // const token = store.getState().global.authToken;
    const authToken = getToken();
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  });

  Http.interceptors.response.use(undefined, (error) => {
    if (error.response.status === 401) {
      // TODO redirect to unauthorized page
      removeToken();
      Http.defaults.headers.common.Authorization = undefined;
      // store.dispatch(resetStoreAction());
      const isLoginUrl = window.location.href.includes("/login");
      if (!isLoginUrl) {
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  });
}
