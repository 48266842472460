import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga4";
const GoogleAnalyticRouter = ({ history }) => {
  useEffect(() => {

    function sendAnalytics(pathname, search) {
      ReactGA.set({ page: `${pathname}${search}`});
      ReactGA.send({ hitType: "pageview", page: `${pathname}${search}` });
    }
    const { pathname, search} = history?.location
    sendAnalytics(pathname, search)
    history.listen((location, action) => {
      const { search, pathname} = location; 
      console.log("Recoring page view ", {pathname, search});
      sendAnalytics(pathname, search);
    });
  }, [])
  

  return <div></div>;
};

export default withRouter(GoogleAnalyticRouter);
