import Http from "common/Http";

const ENDPOINTS = {
  PAYMENT: "/payment",
  GENERATE_TRANSACTION: "/payment/generate",
  VERIFY_PAYMENT: "/payment/verify",
};

export const generatePaymentTransaction = async (sessionId) => {
  const res = await Http.get(`${ENDPOINTS.GENERATE_TRANSACTION}/${sessionId}`);
  return res?.data;
};

export const verifyPayment = async (payload) => {
  const res = await Http.post(`${ENDPOINTS.VERIFY_PAYMENT}`, payload);
  return res?.data;
};
