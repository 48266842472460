import Http from "common/Http";

const ENDPOINTS = {
  SEND_OTP: "/account/send-otp",
  VERIFY_OTP: "/account/verify-otp",
  ME: "/account/me"
};

export const sendOtp = async (data) => {
  const res = await Http.post(ENDPOINTS.SEND_OTP, data);
  return res?.data;
};

export const verifyOtp = async (data) => {
  const res = await Http.post(ENDPOINTS.VERIFY_OTP, data);
  return res?.data;
};


export const getMe = async () => {
  const res = await Http.get(ENDPOINTS.ME);
  return res?.data;
};
