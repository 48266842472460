import axios from "axios";

const Http = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:4445/",
});

export default Http;


/***
 *
 * @param {import("axios").AxiosRequestConfig} baseConfig
 */
export function axiosBaseQuery(baseConfig) {
  return request;

  /**
   *
   * @param {import("axios").AxiosRequestConfig} config
   */
  async function request(config = {}) {
    const url = config.url ? baseConfig.url + config.url : baseConfig.url;
    try {
      const response = await Http.request({ ...baseConfig, ...config, url });

      return {
        ...response,
        data: response.data,
        message: response.data?.message || null,
        status: response.status || 200,
        meta: { request: response.request, response },
      };
    } catch (error) {
      const statusCode = error.response.status;
      const errorData = error.response.data;
      console.log("@@ data ", {statusCode, errorData})
      // for 
      return {
        error: error.response
          ? {
              message: '',
              status: error.response.status,
              data: error.response.data,
            }
          : {
              message: 'Something went wrong',
              data: { message: 'Something went wrong' },
            },
      };
    }
  }
}
