import ArikLogo from "assets/airlines/arik_logo.png";
import AirPeace from "assets/airlines/air-peace.png";
import AeroLogo from "assets/airlines/aero.png";
import Azman from "assets/airlines/azman.png";
import DanaLogo from "assets/airlines/dana_logo.png";
import IbomLogo from "assets/airlines/ibom-air.png";
import MaxAirLogo from "assets/airlines/max_air.png";
import UnitedLogo from "assets/airlines/united.png";
import GreenAfricaLogo from "assets/airlines/green-africa.png";

export const FLIGHT_LOGOS = {
  arik: ArikLogo,
  aero: AeroLogo,
  azman: Azman,
  airpeace: AirPeace,
  dana_air: DanaLogo,
  ibom: IbomLogo,
  maxair: MaxAirLogo,
  united: UnitedLogo,
  green_africa: GreenAfricaLogo,
};

export const FLIGHT_LINKS = {
  arik: "https://www.arikair.com/",
  aero: "https://flyaero.com/",
  azman: "https://www.airazman.com/",
  airpeace: "https://www.flyairpeace.com/",
  dana_air: "https://www.flydanaair.com/",
  ibom: "https://www.ibomair.com/",
  maxair: "https://maxair.com.ng/",
  united: "https://flyunitednigeria.com/",
  green_africa: "https://www.greenafrica.com/",
};

export const LOCATIONS = [
  {
    label: "Lagos",
    value: "LOS",
  },
  {
    label: "Abuja",
    value: "ABV",
  },
  {
    label: "Port Harcourt",
    value: "PHC",
  },
  {
    label: "Asaba",
    value: "ABB",
  },
  {
    label: "Benin",
    value: "BNI",
  },
  {
    label: "Enugu",
    value: "ENU",
  },
  {
    label: "Ilorin",
    value: "ILR",
  },
  {
    label: "Jos",
    value: "JOS",
  },
  {
    label: "Kano",
    value: "KAN",
  },
  {
    label: "Maiduguri",
    value: "MIU",
  },
  {
    label: "Owerri",
    value: "QOW",
  },
  {
    label: "Sokoto",
    value: "SKO",
  },
  {
    label: "Warri",
    value: "QRW",
  },
  {
    label: "Yola",
    value: "YOL",
  },

  {
    label: "UYO",
    value: "QUO",
  },

  {
    label: "Calabar",
    value: "CBQ",
  },

  {
    label: "Kebbi",
    value: "KEB",
  },
  {
    label: "Gombe",
    value: "GMO",
  },
  {
    label: "Bauchi",
    value: "BCU",
  },
  {
    label: "Akure",
    value: "AKR",
  },
  {
    label: "Kaduna",
    value: "KAD",
  }
];

export const findLocationByVal = (val) =>
  LOCATIONS.find((l) => l.value === val);

export const TRIP_WAY = [
  { label: "One Way", value: "One Way" },
  { label: "Return", value: "Return" },
];
export const FLIGHT_TYPE = [
  { label: "Economy", value: "Economy" },
  { label: "Premuim", value: "Premuim" },
  { label: "Business", value: "Business" },
];

export const AIRLINES = {
  arik: "Arik Air",
  aero: "Aero Contractors",
  azman: "Azman Air",
  airpeace: "AirPeace",
  dana_air: "Dana",
  ibom: "Ibom Air",
  maxair: "MaxAir",
  united: "United Nigeria",
  green_africa: "Green Africa",
};

export const isLocalHost = window.location.hostname.includes("local");

export const WAITING_LIST_KEY = "@fc_wiating_list";

export const alreadyInWaitingList = () => {
  return window.localStorage.getItem(WAITING_LIST_KEY);
};

export const markBrowserAsJoinWaitingList = () => {
  if (!alreadyInWaitingList()) {
    window.localStorage.setItem(WAITING_LIST_KEY, "@fc_waiting");
  }
};

export const TITLES = [
  { name: "Mr", label: "Mr" },
  { name: "Mrs", label: "Mrs" },
  { name: "Miss", label: "Miss"},
];

export const GENDERS = [
  { name: "Male", label: "Male" },
  { name: "Female", label: "Female" },
  {name: "Others", label: "Others"}
];

export const PASSENGER_TYPES = [
  {label: "Adult", name: "adult_fare"},
  { label: "Child", name: "child_fare" },
  { label: "Infant", name: "infant_fare"}
]

export const AFFLIATE_CATEGORIES = [
  
  { name: "ATA", label: "ATA" },
  { name: "Frequent Flying Group", label: "FFI" },
];
