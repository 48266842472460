import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as FbSvg } from "assets/svgs/fb.svg";
import { ReactComponent as InstSvg } from "assets/svgs/insta.svg";
import { ReactComponent as TwitterSvg } from "assets/svgs/twitter.svg";
import DownloadApp from "pages/components/DownloadApp";
import { RouteEnum } from "routes"
import AppContext from "context";

const Footer = () => {
  const { isFlutterWebview } = useContext(AppContext);
  const footerLinks = [
    {
      name: "Contact Us",
      link: RouteEnum.ContactUs
    },
    {
      name: "Terms & Policy",
      link: RouteEnum.TermsAndPolicy
    },

    {
      name: "About Us",
      link: RouteEnum.AboutUs
    },
    {
      name: "Affliate Program",
      link: RouteEnum.AffliateProgram
    },
  ];
  return (
    <footer className="mt-auto">
      <div className="md:h-[267px]  flex  flex-col md:flex-row md:justify-between md:items-start bg-site-secondary text-white  px-8 md:px-[144px] py-8 md:py-[51px] space-y-4 md:space-y-0">
        <div className="flex flex-col">
          <div className="font-bold text-[21px] leading-[25.2px]">
            <Link to="/">FlightCompare</Link>
          </div>
          <div className="text-white text-[12px]">Copyright ©️ 2023</div>
          {!isFlutterWebview && <DownloadApp />}
        </div>
        <div className="space-y-1">
          <div className="font-bold text-[21px] leading-[25.2px]">Company</div>
          <div className="flex flex-col space-y-2">
            {footerLinks.map(({ name, link }) => {
              return (
                <Link
                  to={link}
                  key={name}
                  className="hover:underline hover:text-site-primary"
                >
                  {name}
                </Link>
              );
            })}
          </div>
          <div></div>
        </div>

        <div className="flex flex-col space-y-1">
          <span className="font-bold text-[21px] leading-[25.2px]">
            GET IN TOUCH
          </span>
          <span className="font-[400] text-gray-400  text-base leading-6">
            General Enquiries
          </span>
          <span className="font-normal text-base leading-6">
            <a href = "mailto:  contact@shorthaul.app">
            contact@shorthaul.app
            </a>
          </span>
          <span className="font-normal text-base leading-6">
            +234-90-6999-1606
          </span>
          <div className="flex flex-row mt-3 space-x-2 hover:cursor-pointer">
            <div>
              <FbSvg />
            </div>
            <div>
              <InstSvg />
            </div>
            <div>
              <TwitterSvg />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
