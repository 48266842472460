import React, { useEffect, useLayoutEffect, useReducer } from "react";
import GoogleAnalyticRouter from "components/GoogleAnalyticRouter";
import { Route, Switch, useLocation } from "react-router-dom";
import Footer from "Footer";
import Header from "Header";
import AppContext from "context";
import {
  bookingReducer,
  initialState,
  SET_AUTH,
  SET_FETCHING_AUTH,
} from "reducer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminContainer from "pages/admin/AdminContainer";
import PublicContainer from "PublicContainer";
import CustomerContainer from "pages/customer/CustomerContainer";
import MobileNav from "MobileNav";
import { useMutation } from "react-query";
import { logger } from "common/utils";
import { getMe } from "pages/account/api";
import queryString from "query-string";
import BookingSummaryMobile from "pages/trip/BookingSummaryMobile";

export default function App({ isFlutterApp = false }) {
  const [
    {
      passengers,
      confirmation,
      auth,
      selectedBooking,
      storeUpdated,
      session_id: sessionId,
      hasReadTermsAndCondition,
      authToken,
      selectedAirlineConfig,
      isFetchingAuth,
      showBottomSheetFilters,
    },
    dispatch,
  ] = useReducer(bookingReducer, initialState);

  const { pathname, search } = useLocation();
  const { platform } = queryString.parse(search);
  const isFlightBookingPage = pathname === "/booking";

  const { mutate: mutateGetMe } = useMutation(getMe, {
    onSuccess(res) {
      dispatch({ type: SET_FETCHING_AUTH, payload: true });
      dispatch({ type: SET_AUTH, payload: res });
    },
  });

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      const width = window.innerWidth;
      console.log("Width = ", width);
    });
  }, []);

  useEffect(() => {
    if (platform && platform === "flutterApp") {
      window.localStorage.setItem("isFlutterApp", "flutterApp");
    }
  }, [platform, isFlutterApp]);

  useEffect(() => {
    if (authToken) {
      mutateGetMe();
    }
  }, [authToken, mutateGetMe]);

  const isFlutterWebview = isFlutterApp || platform;
  return (
    <div className="flex flex-col bg-gray-100 sm:overflow-hidden overflow-y-auto h-full min-h-screen mx-auto md:m-0">
      <GoogleAnalyticRouter />
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover={false}
      />
      <AppContext.Provider
        value={{
          passengers,
          confirmation,
          auth,
          selectedBooking,
          storeUpdated,
          dispatch,
          sessionId,
          hasReadTermsAndCondition,
          authToken,
          selectedAirlineConfig,
          isFlutterWebview,
          isFetchingAuth,
          showBottomSheetFilters,
        }}
      >
        <Header />
        <main className="mt-[30px] mb-3">
          <React.Suspense fallback={""}>
            <Switch>
              <Route path="/admin" component={AdminContainer} />
              <Route path="/app" component={CustomerContainer} />
              <Route path="" exact={true} component={PublicContainer} />
            </Switch>
          </React.Suspense>
        </main>
        {/* {!isFlutterWebview && <Footer />} */}
        <Footer />
        <div className="md:hidden">
          {/* on mobile view hide the menu bar when the user is on the booking page */}
          {isFlightBookingPage && <BookingSummaryMobile />}
          {!isFlightBookingPage && <MobileNav />}
        </div>
      </AppContext.Provider>
    </div>
  );
}
