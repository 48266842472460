import React, { useContext, useEffect, useLayoutEffect } from "react";
import { findLocationByVal } from "common/Constants";
import Button from "components/Button";
import AppContext from "context";
import { NGN } from "pages/flight/utils";
import { useMutation } from "react-query";
import { generatePaymentTransaction, verifyPayment } from "pages/payment/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { RouteEnum } from "routes";
import { enableProceedToPayment, logger, passengerIsNotValid, validateEmail } from "common/utils";
import {
  getBookingCostingDetails,
  Info,
  lunchPaystackPopup,
} from "./BookingSummary";
import { updateBookingSession } from "./api";

const BookingSummaryMobile = () => {
  const {
    selectedBooking = {},
    passengers = [],
    sessionId,
    selectedAirlineConfig,
    hasReadTermsAndCondition,
    confirmation,
    auth,
  } = useContext(AppContext);

  const history = useHistory();

  const checkBookingExpired = (error) => {
    const { message } = error?.response?.data?.detail;
    console.log("res", message);
    if (message === "SESSION_EXPIRED") {
      toast.error("Your booking session has expired");
      setTimeout(() => {
        history.push("/");
      }, 3000);
    }
  };

    // update our server anytime we make a change
    const { mutate: onUpdateBookingSession } = useMutation(updateBookingSession, {
      onSuccess(res) {
        // after updating the backend with lates details show the paystack
        onLoadTransactionReference(sessionId);
      },
      onError(error) {
        checkBookingExpired(error);
      },
    });

  const handlerUpdateSession = () => {
    onUpdateBookingSession({
      details: selectedBooking,
      passengers,
      session_id: sessionId,
      confirmation,
      created_by: loggedInUserId,
    });
  };

  const {
    dep_time,
    arrival_time,
    flightInfo = {},
    originalCost,
  } = selectedBooking;

  const { arrival_city, dep_city } = flightInfo;

  const { costBreakdown, totalCost } = getBookingCostingDetails(
    passengers,
    originalCost,
    selectedAirlineConfig
  );

  
  const enablePayNow = enableProceedToPayment(
    confirmation,
    hasReadTermsAndCondition
  );

  const loggedInUserId = auth?._id;

  const {
    mutate: onLoadTransactionReference,
    isLoading: isLoadingTransactionReference,
    error: generateTransactionRefError,
  } = useMutation(generatePaymentTransaction, {
    onSuccess(res) {
      payWithPaystack(res);
    },
  });

  const {
    mutate: onVerifyPayment,
    isLoading: isLoadingPaymentVerification,
    error: paymentVerificationError,
  } = useMutation(verifyPayment, {
    onSuccess(res) {
      logger("Paystack Response", res);
      // send the use to print their booking
      history.push(`${RouteEnum.Print}?reference=${res?.data?.reference}`);
    },

    onError(err) {
      toast(
        "Unable to complete payment, please try again or contact our support."
      );
      logger("Error with paystack");
    },
  });

  const createPassengerBooking = (paystackRespone) => {
    console.log("Paystack Response ", paystackRespone);
    const { reference, status, message, transaction } = paystackRespone;
    const bookingPayload = {
      reference,
      status,
      message,
      session_id: sessionId,
      transaction,
    };
    onVerifyPayment(bookingPayload);
  };

  const payWithPaystack = (paymentInfo) => {
    const formElem = document.getElementById("paystackModal");
    // TODO check if the script has been loaded before
    let payStackInline = document.createElement("script");
    payStackInline.setAttribute("id", "rrPaystack");
    payStackInline.onload = () => {
      lunchPaystackPopup(paymentInfo, createPassengerBooking);
    };
    payStackInline.setAttribute("src", "https://js.paystack.co/v1/inline.js");
    formElem.appendChild(payStackInline);
  };

  const handlePayNow = () => {
    const { email, phone } = confirmation;
    if (!email || !validateEmail(email)) {
      toast.error("Please provide a valid confirmation email");
      return;
    }

    if (!phone) {
      toast.error("Provide an active phone number");
      return;
    }

    const passengerNotValidMessage = passengerIsNotValid(passengers);
    if (passengerNotValidMessage) {
      toast.error(passengerNotValidMessage);
      return;
    }
    handlerUpdateSession()
    // onLoadTransactionReference(sessionId);
  };


  useEffect(() => {
    if (generateTransactionRefError) {
      toast.error("Error generating transaction reference");
    }
  }, [generateTransactionRefError]);

  const disablePayNowBtn =
    isLoadingTransactionReference ||
    isLoadingPaymentVerification ||
    !enablePayNow;

  const showTravelDetails = false;

  useLayoutEffect(() => {
    if (window.Tawk_API.hideWidget) {
      // window.Tawk_API.hideWidget();
    }
  }, []);

  return (
    <div className="h-[25vh] bg-white shadow-cards w-full fixed top-[75vh]">
      <div className="flex flex-col px-[25px] py-[4px] ">
        <div className="font-black text-center text-[20px] leading-[28px]">
          Booking Summary
        </div>
        {/* Travel details */}
        {showTravelDetails && (
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col space-y-1">
              <div className="font-bold text-[14px] leading-[22px] text-gray-400">
                {dep_time}
              </div>
              <div className="font-black text-[20px] leading-[28px]">
                {dep_city}
              </div>
              <div className="text-[12px] leading-5 font-bold text-gray-400">
                {findLocationByVal(dep_city)?.label}
              </div>
            </div>

            <div className="flex flex-col space-1-2">
              <div className="font-bold text-[14px] leading-[22px] text-gray-400">
                {arrival_time}
              </div>
              <div className="font-black text-[20px] leading-[28px]">
                {arrival_city}
              </div>
              <div className="text-[12px] leading-5 font-bold text-gray-400">
                {findLocationByVal(arrival_city)?.label}
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col">
          {/* Breakdown */}
          <div className="flex flex-col space-y-[9px] ">
            {Object.keys(costBreakdown).map((k) => {
              const { count, cost } = costBreakdown[k];
              return (
                <Info key={`key-${k}`} cost={cost} count={count} label={k} />
              );
            })}
          </div>

          {/* Total */}

          <div className="flex flex-row justify-between">
            <span className="font-normal text-gray-700 ">Total</span>
            <span className="font-bold text-site-primary ">
              {NGN(totalCost).format()}
            </span>
          </div>
        </div>

        {!disablePayNowBtn && (
          <div className="flex flex-row justify-between mt-1">
            <Button
              disabled={disablePayNowBtn}
              onClick={handlePayNow}
              className="bg-site-primary rounded h-[48px] w-full  text-white font-bold"
            >
              Pay Now
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingSummaryMobile;
