import currency from "currency.js";
import sortBy from "lodash/sortBy";
import dayjs from "dayjs";
export const NGN = (value) => currency(value, { symbol: "₦ ", separator: "," });

export const materializeFlightData = (payload, flightType = "Economy") => {
  const economyResult = [];
  Object.keys(payload).forEach((flightKey) => {
    const flightResult = payload[flightKey];
    flightResult.forEach((flight) => {
      if (flightType in flight) {
        let originalCost = flight[flightType]
          ?.replace(/ngn/i, "")
          .replace(/ /g, "")
          .replace(/,/g, "");

        const arrivalTime = flight["arrival_time"];
        const depTime = flight["dep_time"];

        economyResult.push({
          ...flight,
          airline: flightKey,
          cost: NGN(originalCost).format(),
          sortCost: parseFloat(originalCost),
          originalCost,
          time: calculateFlightTime(depTime, arrivalTime),
        });
      }
    });
  });

  return sortBy(economyResult, [
    function (o) {
      return o.sortCost;
    },
  ]);
};

export const getAirlines = (res) => {
  return Object.keys(res);
};

export const filterMaterializeDataByAilines = (data, airlines = []) => {
  if (!airlines.length) {
    return data;
  }
  return data.filter((d) => {
    const { airline } = d;
    return airlines.includes(airline);
  });
};

export const calculateFlightTime = (fromTime, toTime) => {
  let ft = parseFloat(fromTime.split(":").join("."));
  let tt = parseFloat(toTime.split(":").join("."));

  let result = tt - ft;
  // eg tt = 00:50 and ft = 23:50 = -23
  // so doing 24 - 23 = 1hr
  if (result < 0) {
    result = 24 + result;
  }
  return result.toFixed(2);
};

export const formatTime = (t) => {
  let timeString = `${t}`;
  const [hr, min] = timeString.split(".");
  return `${hr}hr:${min}mins`;
};

