import React, { useContext } from "react";
import { ReactComponent as MobileNavHome } from "assets/svgs/MobileNavHome.svg";
import { ReactComponent as MobileNavBooking } from "assets/svgs/MobileNavBooking.svg";
import { ReactComponent as MobileNavProfile } from "assets/svgs/MobileNavProfile.svg";
import { Link, useLocation, useHistory } from "react-router-dom";
import { RouteEnum } from "routes";
import AppContext from "context";
import { SET_SHOW_BOTTOM_SHEET_FLIGHT_RESULT_FILTER } from "reducer";

const MobileNav = () => {
  const { authToken, isFlutterWebview, auth, dispatch } =
    useContext(AppContext);
  const { user_type: userType } = auth;
  const isAdminUser = userType === "ADMIN";
  const { pathname } = useLocation();
  const isFlightResultPage = pathname === "/flight-result";

  const showFlightResultFiltersBottomSheet = () => {
    dispatch({
      payload: true,
      type: SET_SHOW_BOTTOM_SHEET_FLIGHT_RESULT_FILTER,
    });
  };
  return (
    <div className="h-[12vh] bg-white shadow-cards w-full fixed top-[88vh]">
      <div className="flex flex-row justify-between items-center px-[25px]  py-[24px]">
        <Nav to={RouteEnum.Landing}>
          <MobileNavHome />
        </Nav>
        {authToken && (
          <Nav title="Booking" to={RouteEnum.CustomerBooking}>
            <MobileNavBooking />
          </Nav>
        )}

        {isFlightResultPage && (
          <Nav onClick={showFlightResultFiltersBottomSheet} title="Filter">
            <MobileNavBooking />
          </Nav>
        )}
        {authToken && (
          <Nav title="Profile" to={RouteEnum.CustomerProfile}>
            <MobileNavProfile />
          </Nav>
        )}
      </div>
    </div>
  );
};

const Nav = ({ title = "Home", to = null, children, onClick = () => {} }) => {
  const history = useHistory();

  const handleOnClick = () => {
    if (to) {
      history.push(to);
    } else {
      onClick && onClick();
    }
  };
  return (
    <div
      to={to}
      onClick={handleOnClick}
      className="flex flex-col items-center hover:pointer-events-auto"
    >
      {children}
      <span className="text-sm">{title}</span>
    </div>
  );
};

export default MobileNav;
