import { Suspense as ReactSuspense } from "react";
import Loader from "./Loader";

/**
 *
 * @param {import('react').SuspenseProps} props
 */
function Suspense(props) {
  return <ReactSuspense {...props} />;
}

Suspense.defaultProps = {
  fallback: <Loader />,
};

export default Suspense;
