import { getToken, setToken as setCookieToken, removeToken } from "common/Cookies";
import AppContext from "context";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SET_AUTH, SET_TOKEN, SET_USERNAME } from "reducer";
import { RouteEnum } from "routes";

const defaultUser = {
  username: "Guest",
  last_login: null,
  user_type: "PASSENGER",
  _id: null
}

const useToken = () => {
  const [token, setToken] = useState(null);
  const { dispatch } = useContext(AppContext);
  const history = useHistory()

  const dispatchSetToken = (authToken) => {
    dispatch({ type: SET_TOKEN, payload: authToken });
    setToken(authToken);
    setCookieToken(authToken);
  };

  const dispatchLogoutToken = () => {
    removeToken();
    dispatch({ type: SET_TOKEN, payload: null });
    dispatch({ type: SET_AUTH, payload: defaultUser });
    history.push(RouteEnum.Landing)
  };

  useEffect(() => {
    const cookieToken = getToken();
    if (cookieToken) {
      dispatchSetToken(cookieToken);
    }
  }, []);


  return {
    token,
    dispatchSetToken,
    dispatchLogoutToken
  };
};

export default useToken;
